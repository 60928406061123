import ReactDOM from "react-dom";
import React from "react";
import RoutePage from "./RoutePage";
import "./style.scss";
import "./index.css";
import WalletProvider from "./Wallet";

ReactDOM.render(
  <WalletProvider>
    <React.StrictMode>
      <RoutePage />
    </React.StrictMode>
  </WalletProvider>,
  document.getElementById("root")
);
