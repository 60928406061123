import { useContext, useEffect, useState } from "react";
import NavBar from "./components/NavBar";
import { WalletContext } from "./Wallet";
import ProjectList from './Constant';
import SearchIcon from "./components/SearchIcon";
import Paras from "./components/social/Paras";
import Discord from "./components/social/Discord";
import Twitter from "./components/social/Twitter";
import Website from "./components/social/Website";


const Card = ({ data }) => {
  const { dark } = useContext(WalletContext);
  return (
    <div className={`min-w-[350px] flex flex-col justify-start items-center  ${!dark ? "bg-white border-0" : "bg-black border-1"} rounded-[25px] border-[#00396D]`}>
      <div className={`h-[300px] w-full rounded-[25px] bg-transparent flex flex-row justify-center items-center mt-8`}>
        <img src={data.images.logoUrl} className="h-full rounded-lg" />
      </div>
      <div className="flex flex-col justify-center items-center p-[20px] w-full space-y-6">
        <div
          className="bg-[#00396D] text-white rounded-md w-full text-[14px] uppercase text-center py-3 cursor-pointer"
          onClick={() => {
            window.location.href = `/projects/${data.url}`;
          }}
        >
          {data.title}
        </div>
        <div className={`flex flex-row items-center justify-around w-full  ${!dark ? "text-black" : "text-white"}`}>
          {
            data.links.paras &&
            <div
              className="relative border-[#00396D] border-[4px] bg-transparent w-[60px] h-[60px] flex flex-row justify-center items-center rounded-[50px] text-[20px] cursor-pointer"
              onClick={() => { window.open(data.links.paras) }}
            > <Paras fill={dark ? "#ffffff" : "000000"} className="absolute top-[17px] left-[19px]" /> </div>
          }
          {
            data.links.discord &&
            <div
              className="relative border-[#00396D] border-[4px] bg-transparent w-[60px] h-[60px] flex flex-row justify-center items-center rounded-[50px] text-[20px] cursor-pointer"
              onClick={() => { window.open(data.links.discord) }}
            > <Discord fill={dark ? "#ffffff" : "000000"} className="absolute top-[17px] left-[15px]" /> </div>
          }
          {
            data.links.twitter &&
            <div
              className="relative border-[#00396D] border-[4px] bg-transparent w-[60px] h-[60px] flex flex-row justify-center items-center rounded-[50px] text-[20px] cursor-pointer"
              onClick={() => { window.open(data.links.twitter) }}
            > <Twitter fill={dark ? "#ffffff" : "000000"} className="absolute top-[18px] left-[15px]" /> </div>
          }
          {
            data.links.website &&
            <div
              className="relative border-[#00396D] border-[4px] bg-transparent w-[60px] h-[60px] flex flex-row justify-center items-center rounded-[50px] text-[20px] cursor-pointer"
              onClick={() => { window.open(data.links.website) }}
            > <Website fill={dark ? "#ffffff" : "000000"} className="absolute top-[14px] left-[14px]" /> </div>
          }
        </div>
      </div>
    </div>
  )
}

const App = () => {
  const [search, setSearch] = useState('');
  const [list, setList] = useState(ProjectList);
  const {
    wallet,
    dark,
    setStakingContractId,
    setStakingContractId2,
    setNftContractId,
    setNftContractId2,
  } = useContext(WalletContext);

  useEffect(() => {
    setStakingContractId('');
    setStakingContractId2('');
    setNftContractId('');
    setNftContractId2('');
  }, [])

  useEffect(() => {
    let item = ProjectList.filter((item) => eachIndex(item, search));
    setList(item);
  }, [search])

  const eachIndex = (e, search) => {
    return e.title.toLowerCase().includes(search.toLowerCase())
  }

  return (
    <div className={`flex flex-col justify-start items-center min-h-[100vh] ${!dark ? "" : "bg-black"}`}>
      <NavBar />
      {wallet && wallet.isSignedIn() &&
        <>
          <div className="w-full h-[200px] mt-[88px] flex flex-row justify-center items-center">
            <div className="relative">
              <SearchIcon
                className="absolute left-[10px] top-[10px]"
                fill={dark ? "#FFFFFF" : "#777777"}
              />
              <input
                className={` ${!dark ? "bg-[#f5f5f5] text-black" : "bg-[#121212] text-white"} py-2 pl-16 h-[50px] rounded-[20px] w-[500px] -md:w-[300px]`}
                placeholder="Search..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          {
            list.length > 0 ?
              <div className={`${!dark ? "bg-[#f5f5f5]" : "bg-[#121212]"} mb-[100px] rounded-[20px] p-[50px] -xl:p-[30px] -lg:p-[20px]  grid grid-cols-3 -lg:grid-cols-2 -md:grid-cols-1 gap-8 -sm:gap-4`}>
                {list.map(project => {
                  return (
                    <Card key={project.id} data={project} />
                  )
                })}
              </div> :
              <div className={`w-[350px] md:w-[700px] xl:w-[1000px] 2xl:w-[1400px] h-[200px] flex flex-row justify-center items-center ${!dark ? "text-black bg-[#f5f5f5]" : "text-white bg-[#121212]"} mb-[100px] rounded-[20px]`}>
                There is no project.
              </div>
          }
        </>
      }
    </div >
  );
}

export default App;
