const MenuIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" className={props.className} onClick={props.onClick} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 4.49998H20.9993" stroke={props.stroke} strokeWidth="1.49993" strokeLinecap="round" />
      <path d="M4 11.499H20.9993" stroke={props.stroke} strokeWidth="1.49993" strokeLinecap="round" />
      <path d="M4 18.5H20.9993" stroke={props.stroke} strokeWidth="1.49993" strokeLinecap="round" />
    </svg>
  )
}

export default MenuIcon;