import React, { useContext, useState } from "react";
import { WalletContext } from "../Wallet";
import { useNavigate } from "react-router-dom";
import Logo from "../img/vicon.png";
import MenuIcon from "./MenuIcon";

const Header = ({ wallet, onWallet, data, from }) => {
  const { ancBalance } = useContext(WalletContext);
  const navigate = useNavigate();
  const { dark, setDark } = useContext(WalletContext);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const miniAddress = (address) => {
    const len = address.length;
    const shortAccAddress =
      address.slice(0, 5) + "..." + address.slice(len - 4, len);
    return shortAccAddress;
  };
  return (
    <>
      <div
        className={`fixed inset-x-0 top-0 z-10 py-[12px] flex flex-row justify-around -sm:justify-between items-center ${
          !dark ? "text-black" : "text-white"
        } -sm:px-[15px] blur-bg`}
        style={{
          background: dark ? "rgba(0, 0, 0, 0.7)" : "rgba(255, 255, 255, 0.7)",
        }}
      >
        <div className="text-[24px]">
          <img
            src={Logo}
            className="h-[90px] -lg:h-[70px] cursor-pointer"
            onClick={() => navigate("/")}
          />
        </div>
        <div className="flex flex-row justify-start space-x-12 -sm:space-x-4 items-center">
          <div
            className="cursor-pointer font-extrabold -lg:hidden"
            onClick={() => {
              window.location.href = "https://market.vexedapes.com/";
            }}
          >
            MARKET
          </div>
          <div
            className="cursor-pointer font-extrabold -lg:hidden"
            onClick={() => {
              window.location.href = "https://raffle.vexedapes.com/";
            }}
          >
            RAFFLE
          </div>
          <div
            className="cursor-pointer font-extrabold -lg:hidden"
            onClick={() => {
              window.location.href = "https://battle.vexedapes.com/";
            }}
          >
            BATTLE
          </div>
          <div
            className="cursor-pointer font-extrabold -lg:hidden"
            onClick={() => navigate("/")}
          >
            STAKING
          </div>
          {wallet && wallet.isSignedIn() ? (
            <div
              className={`flex flex-row justify-center items-center p-4 -lg:hidden ${
                dark ? "bg-black" : "bg-white text-black"
              } rounded-lg`}
            >
              {from == "1" && (
                <div className="inline-block min-w-[100px]">
                  {ancBalance} {data.tokenCode}
                </div>
              )}
              <div
                className="rounded-md flex justify-center items-center bg-[#00396D] px-[10px] cursor-pointer h-[40px] font-[400] text-[16px] text-white"
                onClick={onWallet}
              >
                {wallet.account().accountId.length > 40
                  ? miniAddress(wallet.account().accountId)
                  : wallet.account().accountId}
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="flex flex-row items-center justify-center">
            <label
              htmlFor="toggleB"
              className="flex items-center cursor-pointer -lg:hidden"
            >
              <div className="relative">
                <input
                  type="checkbox"
                  id="toggleB"
                  className="sr-only"
                  checked={dark}
                  onChange={(e) => {
                    setDark(e.target.checked);
                    localStorage.setItem("dark", e.target.checked);
                  }}
                />
                <div
                  className={`block  ${
                    !dark
                      ? "bg-[#F5F5F5] border-[1px] border-[#aaa]"
                      : "bg-black border-[1px] border-gray-500"
                  } w-14 h-8 rounded-full`}
                ></div>
                <div
                  className={`dot absolute left-1 top-1 ${
                    !dark ? "bg-[#E6E6E6]" : "bg-[#393939]"
                  } w-6 h-6 rounded-full transition flex flex-row justify-center items-center`}
                >
                  {!dark ? (
                    <div className="w-3 h-3 border-2 border-yellow-400 rounded-lg" />
                  ) : (
                    <div className="w-3 h-3 border-2 border-[#00396D] rounded-lg" />
                  )}
                </div>
              </div>
            </label>
            <div className="lg:hidden ml-4">
              <MenuIcon
                className="inline w-[24px] h-[24px] cursor-pointer"
                onClick={() => setShowMobileMenu(!showMobileMenu)}
                stroke={dark ? "white" : "black"}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`fixed top-[94px] left-0 right-0 ${
          dark ? "bg-[#121212] text-white" : "bg-[#e6e6e6] text-black"
        } opacity-99 z-10 px-[25px] flex flex-col justify-start overflow-x-hidden lg:hidden ${
          (showMobileMenu && "h-screen max-h-screen py-[20px] blur-bg") ||
          "h-0 py-0"
        }`}
        style={{
          transition: showMobileMenu
            ? "all 0.2s ease-in"
            : "all 0.15s ease-out",
          background: dark ? "rgba(0, 0, 0, 0.7)" : "rgba(255, 255, 255, 0.7)",
        }}
      >
        <div className="flex flex-col justify-center space-y-[40px] items-start -md:w-[400px] -sm:w-[320px] mx-auto font-[400] text-[20px] leading-[19px] cursor-pointer mt-8">
          <div
            className="cursor-pointer font-extrabold"
            onClick={() => {
              window.location.href = "https://market.vexedapes.com/";
            }}
          >
            MARKET
          </div>
          <div
            className="cursor-pointer font-extrabold"
            onClick={() => {
              window.location.href = "https://raffle.vexedapes.com/";
            }}
          >
            RAFFLE
          </div>
          <div
            className="cursor-pointer font-extrabold"
            onClick={() => {
              window.location.href = "https://battle.vexedapes.com/";
            }}
          >
            BATTLE
          </div>
          <div
            className="cursor-pointer font-extrabold"
            onClick={() => navigate("/")}
          >
            STAKING
          </div>
          <label htmlFor="toggleB" className="flex items-center cursor-pointer">
            <div className="relative">
              <input
                type="checkbox"
                id="toggleB"
                className="sr-only"
                checked={dark}
                onChange={(e) => {
                  setDark(e.target.checked);
                  localStorage.setItem("dark", e.target.checked);
                }}
              />
              <div
                className={`block ${
                  !dark
                    ? "bg-[#F5F5F5] border-[1px] border-[#aaa]"
                    : "bg-black border-[1px] border-gray-500"
                } w-14 h-8 rounded-full`}
              ></div>
              <div
                className={`dot absolute left-1 top-1 ${
                  !dark ? "bg-[#E6E6E6]" : "bg-[#393939]"
                } w-6 h-6 rounded-full transition flex flex-row justify-center items-center`}
              >
                {!dark ? (
                  <div className="w-3 h-3 border-2 border-yellow-400 rounded-lg" />
                ) : (
                  <div className="w-3 h-3 border-2 border-[#00396D] rounded-lg" />
                )}
              </div>
            </div>
          </label>
          {wallet && wallet.isSignedIn() ? (
            <div
              className={`flex flex-row justify-center items-center p-4 ${
                dark ? "bg-black" : "bg-white text-black"
              } rounded-lg`}
            >
              {from == "1" && (
                <div className="inline-block min-w-[100px] text-[14px]">
                  {ancBalance} {data.tokenCode}
                </div>
              )}
              <div
                className="rounded-md flex justify-center items-center bg-[#00396D] px-[10px] cursor-pointer h-[40px] font-[400] text-[16px] text-white"
                onClick={onWallet}
              >
                {wallet.account().accountId.length > 40
                  ? miniAddress(wallet.account().accountId)
                  : wallet.account().accountId}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default Header;
