const ProjectList = [
  {
    id: 0,
    url: "vexedapesclub",
    title: "Vexed Apes Club",
    tokenContractId: "vexedapes_token.near",
    tokenCode: "$VAC",
    contracts: [
      {
        subTitle: "Vexed Apes Club",
        // nftContractId: "nft1.vexedapesmint.near",
        nftContractId: "vexedapesclub.near",
        stakingContractId: "vexedapes_staking.near",
        rate: 24,
        fromParas: false,
      },
    ],
    images: {
      bannerUrl:
        "https://ipfs.fleek.co/ipfs/bafkreib422yetv7xn3ojvva522mezpbaxxkbfo5sorsuurrljz4ldyajd4",
      logoUrl:
        "https://pbs.twimg.com/profile_images/1596320045688393730/jY4lcovD_400x400.jpg",
    },
    links: {
      paras: "https://paras.id/collection/vexedapesclub.near",
      discord: "https://dsc.gg/vexedapesclub",
      twitter: "https://twitter.com/VexedApesClub",
      website: "https://market.vexedapes.com/",
    },
  } /**,
  {
    id: 1,
    url: 'apenearclub',
    title: "Ape Near Club",
    tokenContractId: "anctokenwallet.near",
    tokenCode: "$ANC",
    contracts: [
      {
        subTitle: "BANC",
        nftContractId: "banc.neartopia.near",
        stakingContractId: "stakingancwallet.near",
        rate: 10,
        fromParas: false
      },
      {
        subTitle: "ANC",
        nftContractId: "x.paras.near",
        stakingContractId: "stakingancwallet2.near",
        rate: 20,
        fromParas: true,
        creatorId: "apenearclub.near",
        collectionId: "ape-near-club-by-apenearclubnear",
      }
    ],
    images: {
      bannerUrl: "https://ipfs.fleek.co/ipfs/bafybeibfdgcdnb5fluulu46axhbcefl5tvuf6u67eha6k7r66euobrlipi",
      logoUrl: "https://paras-cdn.imgix.net/bafybeibfdgcdnb5fluulu46axhbcefl5tvuf6u67eha6k7r66euobrlipi?w=800&auto=format,compress",
    },
    links: {
      paras: "https://paras.id/collection/ape-near-club-by-apenearclubnear",
      discord: "https://discord.com/invite/SwdYt73VJp",
      twitter: "https://twitter.com/ApeNearClub",
      website: "https://apenear.club",
    }
  },
  {
    id: 2,
    url: 'kokumokongz',
    title: "Kokumo KongZ",
    tokenContractId: "kokumokongz_token_wallet.near",
    tokenCode: "$PLASMA",
    contracts: [
      {
        subTitle: "Kokumo KongZ",
        nftContractId: "kokumokongz.near",
        stakingContractId: "kokumokongz_staking_wallet.near",
        rate: 5,
        fromParas: false
      }
    ],
    images: {
      bannerUrl: "https://ipfs.fleek.co/ipfs/bafybeidnpetcrbvodf27ywi3is5j3ynqypk6xxdeufrs62y4bxwass4f2m",
      logoUrl: "https://paras-cdn.imgix.net/bafkreiaboggro5ri5eeujb6xzyjbwe45q4w2cp7o3pg5uvjtic6lllkx2i?w=800&auto=format,compress",
    },
    links: {
      paras: "https://paras.id/collection/kokumokongz.near",
      discord: "https://discord.gg/sSpTRBReNe",
      twitter: "https://twitter.com/kokumokongz",
      website: "https://kokumokongz.net/",
    }
  },
  {
    id: 3,
    url: 'pukingaliens',
    title: "Puking Aliens & Lil Squatch Squad",
    tokenContractId: "spewtoken.near",
    tokenCode: "$SPEW",
    contracts: [
      {
        subTitle: "LIL SQUATCH",
        nftContractId: "623c5b25294f600e58f47074.astrogenfunds.near",
        stakingContractId: "etstaking.near",
        rate: 5,
        fromParas: false
      },
      {
        subTitle: "PUKING ALIENS",
        nftContractId: "x.paras.near",
        stakingContractId: "etstaking2.near",
        rate: 10,
        fromParas: true,
        creatorId: "etinks.near",
        collectionId: "puking-aliens-gen-1-by-etinksnear",
      }
    ],
    images: {
      bannerUrl: "https://ipfs.fleek.co/ipfs/bafybeicee7lif2vdfegieiygcdjr5q5u5kgykpwmvnwo26t3eltvdeanvq",
      logoUrl: "https://paras-cdn.imgix.net/bafybeibd52biy6kcvnpilquvwaffvgn7dwn54rswe35jo3li65dfjrvcii?w=800&auto=format,compress",
    },
    links: {
      paras: "https://paras.id/collection/puking-aliens-gen-1-by-etinksnear",
      discord: "https://discord.com/invite/e6gBkwWNgk",
      twitter: "https://twitter.com/pukingaliensnft",
      website: "",
    }
  } */,
];

export default ProjectList;
