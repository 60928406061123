import { PublicKey } from "near-api-js/lib/utils";
import { createTransaction, functionCall } from "near-api-js/lib/transaction";
import { baseDecode } from "borsh";
import { MAX_GAS } from "../Wallet";
// import { getGas, getAmount, getAccessKey } from "./getFunctions";

const getAccessKey = () => {
  const accessKey = JSON.parse(localStorage.getItem("undefined_wallet_auth_key")).allKeys[0]
  return accessKey
}

export const executeMultipleTransactions = async (
  wallet,
  accountId,
  near,
  transactions, 
  gas,
) => {
    const accessKey = getAccessKey();
    const publicKey = PublicKey.from(accessKey)

    const tokenTransactions = await Promise.all(
        transactions.map(async (t, i) => {
            //get block hash
            let block = await near.connection.provider.block({ finality: 'final' });
            let blockHash = baseDecode(block.header.hash);

            return createTransaction(
                accountId,
                publicKey,
                t.receiverId,
                i + 1,
                t.functionCalls.map((fc) => {
                    return functionCall(fc.methodName, fc.args, gas, fc.attachedDeposit)
                }),
                blockHash
            )
        })
    )
    await wallet.requestSignTransactions({ transactions: tokenTransactions })
}