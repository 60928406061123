import React, { Suspense } from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import * as history from "history"
import App from "./App"
import Projects from "./Projects"


const RoutePage = () => {
  const Loader = () => {
    return <>loading...</>
  }
  return (
    <Router history={history}>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route
            path="/"
            element={
              <App />
            }
          />
          <Route
            path="/projects/:url"
            element={
              <Projects />
            }
          >
            <Route path=":url" element={<Projects />} />
          </Route>
        </Routes>
      </Suspense>
    </Router>
  )
}

export default RoutePage;