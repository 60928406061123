import * as nearAPI from "near-api-js";
import React, { useCallback, useEffect, useState } from "react";
import axios from 'axios';
const { KeyPair, keyStores, connect, WalletConnection, utils: { format: { formatNearAmount } } } = nearAPI;

const keyStore = new keyStores.BrowserLocalStorageKeyStore();

export const GAS_FOR_NFT_APPROVE = "20000000000000";
export const GAS_FOR_RESOLVE_TRANSFER = "10000000000000";
export const MAX_GAS = "300000000000000";
export const DEPOSIT = "520000000000000000000";

export const WalletContext = React.createContext({
  near: undefined,
  wallet: undefined,
  nftList: [],
  nftList2: [],
  stakedNftList: [],
  stakedNftList2: [],
  fetchNFTs: () => { },
  fetchNFTs2: () => { },
  fetchStakedNFTs: () => { },
  fetchStakedNFTs2: () => { },
  // here state variables
  ancBalance: 0,
  setancBalance: () => { },
  stakingContractId: undefined,
  setStakingContractId: () => { },
  stakingContractId2: undefined,
  setStakingContractId2: () => { },
  tokenContractId: undefined,
  setTokenContractId: () => { },
  nftContractId: undefined,
  setNftContractId: () => { },
  nftContractId2: undefined,
  setNftContractId2: () => { },
  creatorId: undefined,
  setCreatorId: () => { },
  collectionId: undefined,
  setCollectionId: () => { },
  value: "1",
  setValue: () => { },
  dark: true,
  setDark: () => { },
  balance: 0,
  setBalance: () => {},
  nftListForStake: [],
  setNftListForStake: () => {},
  nftListForUnstake: [],
  setNftListForUnstake: () => {}
})

// connect to NEAR
const WalletProvider = (props) => {
  const [near, setNear] = useState()
  const [wallet, setWallet] = useState()
  const [nftList, setNftList] = useState([])
  const [nftList2, setNftList2] = useState([])
  const [stakedNftList, setStakedNftList] = useState([])
  const [stakedNftList2, setStakedNftList2] = useState([])
  const [ancBalance, setancBalance] = useState(0)

  // constants variables
  const [stakingContractId, setStakingContractId] = useState('');
  const [stakingContractId2, setStakingContractId2] = useState('');
  const [tokenContractId, setTokenContractId] = useState('');
  const [nftContractId, setNftContractId] = useState('');
  const [nftContractId2, setNftContractId2] = useState('');
  const [creatorId, setCreatorId] = useState('');
  const [collectionId, setCollectionId] = useState('');

  // select collect
  const [value, setValue] = useState(0);
  const [dark, setDark] = useState(true);
  const [balance, setBalance] = useState(0);

  //
  const [nftListForStake, setNftListForStake] = useState([]);
  const [nftListForUnstake, setNftListForUnstake] = useState([]);


  const config = {
    networkId: "mainnet",
    keyStore, // optional if not signing transactions
    nodeUrl: "https://rpc.mainnet.near.org",
    walletUrl: "https://wallet.mainnet.near.org",
    helperUrl: "https://helper.mainnet.near.org",
    explorerUrl: "https://explorer.mainnet.near.org",
  };

  const connectToNear = useCallback(async () => {
    try {
      const near = await connect(config);
      const wallet = new WalletConnection(near);
      setNear(near);
      setWallet(wallet);
    } catch (error) {
      console.log(error, "error")
    }
  }, [config])

  const fetchNFTs = async (wallet) => {
    if (wallet && wallet.isSignedIn() && nftContractId) {
      var items = await wallet.account().viewFunction(
        nftContractId,
        "nft_tokens_for_owner",
        {
          account_id: wallet.account().accountId,
          from_index: "0",
          limit: 1000
        }
      );
      if (!items || !items.length) {
        setNftList([])
      } else {
        const contractMetaData = await wallet.account().viewFunction(
          nftContractId,
          "nft_metadata",
          {}
        );
        for (let item of items) {
          item.baseUri = contractMetaData.base_uri
        }
        setNftList(items)
      }
    }
  }

  const fetchNFTs2 = async (wallet) => {
    if (wallet && wallet.isSignedIn() && creatorId && collectionId) {
      await axios.get(`https://api-v2-mainnet.paras.id/token?owner_id=${wallet.account().accountId}&creator_id=${creatorId}&collection_id=${collectionId}`).then(result => result.data).then(function (data) {
        // console.log(data, " : nft list for token2 by user")
        setNftList2(data.data.results);
      }).catch((e) => {
        console.log(e, "error from axios get from paras.id api");
      })
    }
  }

  const fetchStakedNFTs = async (wallet) => {
    if (wallet && wallet.isSignedIn() && stakingContractId && nftContractId) {
      var stakedItemInfos = await wallet.account().viewFunction(
        stakingContractId,
        "get_staking_informations_by_owner_id",
        {
          account_id: wallet.account().accountId,
          from_index: "0",
          limit: 1000
        }
      );
      var items = [];
      for (let stakedItemInfo of stakedItemInfos) {
        var item = await wallet.account().viewFunction(
          nftContractId,
          "nft_token",
          {
            token_id: stakedItemInfo.token_id
          }
        );
        if (item)
          items.push(item);
      }
      if (!items || !items.length) {
        setStakedNftList([])
      } else {
        var contractMetaData = await wallet.account().viewFunction(
          nftContractId,
          "nft_metadata",
          {}
        );
        for (let item of items) {
          item.baseUri = contractMetaData.base_uri
        }
        setStakedNftList(items)
      }
    }
  }

  const fetchStakedNFTs2 = async (wallet) => {
    if (wallet && wallet.isSignedIn() && stakingContractId2 && nftContractId2 && creatorId && collectionId) {
      var stakedItemInfos = await wallet.account().viewFunction(
        stakingContractId2,
        "get_staking_informations_by_owner_id",
        {
          account_id: wallet.account().accountId,
          from_index: "0",
          limit: 1000
        }
      );

      var items = [];
      for (let stakedItemInfo of stakedItemInfos) {
        await axios.get(`https://api-v2-mainnet.paras.id/token?token_id=${stakedItemInfo.token_id}&creator_id=${creatorId}&collection_id=${collectionId}`).then(result => result.data).then(function (data) {
          if (data && data.data && data.data.results && data.data.results.length > 0) {
            items.push(data.data.results[0])
          }
        }).catch((e) => {
          console.log(e, "error from axios get from paras.id api");
        })
      }
      if (!items || !items.length) {
        setStakedNftList2([])
      } else setStakedNftList2(items)
    }
  }

  useEffect(() => {
    connectToNear()
  }, [])

  useEffect(() => {
    if(localStorage.getItem('dark') === 'true') {
      setDark(true);
    } else if(localStorage.getItem('dark') === 'false') {
      setDark(false);
    }
  }, [])

  return (
    <WalletContext.Provider
      value={{
        near,
        wallet,
        nftList,
        nftList2,
        stakedNftList,
        stakedNftList2,
        ancBalance,
        stakingContractId,
        stakingContractId2,
        nftContractId,
        nftContractId2,
        tokenContractId,
        creatorId,
        collectionId,
        value,
        dark,
        balance,
        nftListForStake,
        nftListForUnstake,
        fetchNFTs,
        fetchNFTs2,
        fetchStakedNFTs,
        fetchStakedNFTs2,
        setancBalance,
        setStakingContractId,
        setStakingContractId2,
        setNftContractId,
        setNftContractId2,
        setTokenContractId,
        setCreatorId,
        setCollectionId,
        setValue,
        setDark,
        setBalance,
        setNftListForStake,
        setNftListForUnstake,
      }}
    >
      {props.children}
    </WalletContext.Provider>
  )
}

export default WalletProvider
