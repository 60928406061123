import React, { useContext, useEffect, useState, useCallback, useRef } from "react";
import { Line } from 'rc-progress';
import { WalletContext, MAX_GAS, DEPOSIT } from "../Wallet";
import * as nearAPI from "near-api-js";
import axios from "axios";
const { utils: { format: { formatNearAmount } } } = nearAPI;
import { executeMultipleTransactions } from "./ExcuteMultipleTx";

function Balance({ data }) {
  const {
    near,
    wallet,
    nftList,
    nftList2,
    stakedNftList,
    stakedNftList2,
    ancBalance,
    value,
    setValue,
    setancBalance,
    fetchNFTs,
    fetchNFTs2,
    fetchStakedNFTs,
    fetchStakedNFTs2,
    // contract ids
    stakingContractId,
    stakingContractId2,
    nftContractId,
    nftContractId2,
    tokenContractId,
    collectionId,
    dark,
    setBalance
  } = useContext(WalletContext)
  const mountedRef = useRef(true)
  const [claimBalance, setClaimBalance] = useState(0)
  const [claimBalance2, setClaimBalance2] = useState(0)
  const [totalStakedSupply, setTotalStakedSupply] = useState(0)
  const [totalStakedSupply2, setTotalStakedSupply2] = useState(0)
  const [totalNftSupply, setTotalNftSupply] = useState(0)
  const [totalNftSupply2, setTotalNftSupply2] = useState(0)

  const onClaim = async (wallet, token_type) => {
    if (wallet && wallet.isSignedIn() && stakingContractId) {
      var result;
      if (token_type == 0) {
        result = await wallet.account().functionCall({
          contractId: stakingContractId,
          methodName: "claim_reward",
          args: {
          },
          gas: MAX_GAS,
          attachedDeposit: "1",
        });
      } else if (token_type == 1 && stakingContractId2) {
        result = await wallet.account().functionCall({
          contractId: stakingContractId2,
          methodName: "claim_reward",
          args: {
          },
          gas: MAX_GAS,
          attachedDeposit: "1",
        });
      }

      updateState(wallet);
    }
  }

  const updateState = async (wallet) => {
    mountedRef.current = true;
    if (wallet && wallet.isSignedIn() && stakingContractId && nftContractId && tokenContractId) {
      let result;
      result = await wallet.account().viewFunction(
        tokenContractId,
        "ft_balance_of",
        {
          account_id: wallet.account().accountId,
        }
      );
      if (!mountedRef.current) return null;
      setancBalance(formatNearAmount(result));

      result = await wallet.account().viewFunction(
        stakingContractId,
        "get_claim_amount",
        { account_id: wallet.account().accountId }
      );
      if (!mountedRef.current) return null;
      setClaimBalance(formatNearAmount(result));

      result = await wallet.account().viewFunction(stakingContractId, "get_supply_staking_informations", {});
      if (!mountedRef.current) return null;
      setTotalStakedSupply(result);

      let result1 = await wallet.account().viewFunction(
        nftContractId,
        "nft_total_supply",
        {
        }
      );
      if (!mountedRef.current) return null;
      setTotalNftSupply(result1);


      //-----------------------paras nft data
      if (stakingContractId2 && collectionId) {
        result = await wallet.account().viewFunction(
          stakingContractId2,
          "get_claim_amount",
          {
            account_id: wallet.account().accountId,
          }
        );
        if (!mountedRef.current) return null;
        // console.log(result, " : claim amount for SPEW")
        setClaimBalance2(formatNearAmount(result));

        result = await wallet.account().viewFunction(stakingContractId2, "get_supply_staking_informations", {});
        setTotalStakedSupply2(result);

        await axios.get(`https://api-v2-mainnet.paras.id/collection-stats?collection_id=${collectionId}`).then(result => result.data).then(data => {

          setTotalNftSupply2(data.data.results.total_cards)
        }).catch((e) => {
          console.log(e, " : error from fetch collection stat data from paras.io api")
        })
      }
    }
  }

  const handleStakeAll = async () => {
    let txDetails = [];
    for (let i = 0; i < nftList.length; i++) {
      if (data.id == 0 || data.id == 1) {
        txDetails.push({
          receiverId: nftContractId,
          functionCalls: [{
            methodName: 'nft_approve',
            args: {
              token_id: nftList[i].token_id,
              account_id: stakingContractId,
              msg: JSON.stringify({ staking_status: "Staking to Platform" })
            },
            attachedDeposit: DEPOSIT
          }]
        })
      } else {
        txDetails.push({
          receiverId: nftContractId,
          functionCalls: [{
            methodName: 'nft_transfer_call',
            args: {
              receiver_id: stakingContractId,
              token_id: nftList[i].token_id,
              approval_id: 0,
              msg: JSON.stringify({ staking_status: "Staking to Platform" })
            },
            attachedDeposit: "1"
          }]
        })
      }
    }
    for (let j = 0; j < nftList2.length; j++) {
      txDetails.push({
        receiverId: nftContractId2,
        functionCalls: [{
          methodName: 'nft_transfer_call',
          args: {
            receiver_id: stakingContractId2,
            token_id: nftList2[j].token_id,
            approval_id: 0,
            msg: JSON.stringify({ staking_status: "Staking to Platform" })
          },
          attachedDeposit: "1"
        }]
      })
    }
    if (txDetails.length > 0) {
      await executeMultipleTransactions(
        wallet,
        wallet.account().accountId,
        near,
        txDetails,
        MAX_GAS,
      );

      fetchNFTs(wallet);
      fetchNFTs2(wallet);
    }
  }

  const handleUnstakeAll = async () => {
    // await wallet.account().functionCall({
    //   contractId: "vexedapes_staking.near",
    //   methodName: "set_nft_contract_id",
    //   args: {
    //     nft_contract_id: "vexedapesclub.near"
    //   },
    //   gas: MAX_GAS,
    //   attachedDeposit: "1"
    // })
    let txDetails = [];
    for (let i = 0; i < stakedNftList.length; i++) {
      txDetails.push({
        receiverId: stakingContractId,
        functionCalls: [{
          methodName: 'unstake',
          args: { token_id: stakedNftList[i].token_id },
          attachedDeposit: "1"
        }]
      })
    }
    for (let j = 0; j < stakedNftList2.length; j++) {
      txDetails.push({
        receiverId: stakingContractId2,
        functionCalls: [{
          methodName: 'unstake',
          args: { token_id: stakedNftList2[j].token_id },
          attachedDeposit: "1"
        }]
      })
    }
    if (txDetails.length > 0) {
      await executeMultipleTransactions(
        wallet,
        wallet.account().accountId,
        near,
        txDetails,
        MAX_GAS,
      );
      fetchStakedNFTs(wallet);
      fetchStakedNFTs2(wallet);
    }
  }

  useEffect(() => {
    updateState(wallet);
    return () => {
      mountedRef.current = false
    }
  }, [
    wallet,
    stakingContractId,
    stakingContractId2,
    nftContractId,
    nftContractId2,
    tokenContractId,
    collectionId,
  ])

  useEffect(() => {
    setBalance(Number(claimBalance) + Number(claimBalance2));
  }, [claimBalance, claimBalance2])

  return (
    <div className="flex flex-col justify-center items-center w-full">

      <div className={`relative flex flex-col justify-center items-center ${!dark ? 'bg-[#F5F5F5]' : 'bg-[#121212]'} rounded-b-lg rounded-tr-lg p-12 w-full mt-[60px]`}>
        <div className="absolute left-0 top-[-58px] flex flex-row justify-start h-[80px]">
          {
            data.contracts.map((contract, index) => {
              return (
                <div
                  key={index}
                  className={`w-[180px] h-[60px] -sm:w-[149px] -sm:text-[14px]
                    ${!dark ? "bg-[#F5F5F5]" : "bg-[#121212]"} 
                    flex flex-row justify-center items-center
                    border-t-4 border-r-4 
                    ${value == index ?
                      "border-t-[#00396D] border-r-[#00396D]" :
                      !dark ? "border-t-[#cccccc] border-r-[#cccccc]" : "border-t-[#272727] border-r-[#272727] bg-[#171717]"
                    } 
                    rounded-t-lg cursor-pointer
                    `
                  }
                  onClick={() => setValue(index)}
                >
                  {contract.subTitle}
                </div>
              )
            })
          }
        </div>
        <div className="flex flex-col justify-center items-center px-[200px] -2xl:px-[0px] -sm:px-0">
          <div className="w-full flex flex-col justify-center items-end">
            <Line
              percent={
                value == 0 ?
                  totalStakedSupply == 0 ? 0 :
                    totalNftSupply != 0 && ((Number(totalStakedSupply) / Number(totalNftSupply)) * 100).toFixed(1) :
                  totalStakedSupply2 == 0 ? 0 :
                    totalNftSupply2 != 0 && ((Number(totalStakedSupply2) / Number(totalNftSupply2)) * 100).toFixed(1)
              }
              strokeWidth={2}
              trailWidth={2}
              strokeColor={!dark ? "#00396D" : "#272727"}
              trailColor={!dark ? "#ffffff" : "#000000"}
              className={`border-2 ${!dark ? "border-[#eeeeee]" : "border-[#272727]"}  rounded-[50px]`}
            />
            <div className="mt-2 pr-1">
              {value == 0 ? totalStakedSupply : totalStakedSupply2} /
              {value == 0 ? totalNftSupply : totalNftSupply2} (
              {value == 0 ?
                totalStakedSupply == 0 ? "0%" :
                  totalNftSupply != 0 && ((Number(totalStakedSupply) / Number(totalNftSupply)) * 100).toFixed(1) + " %" :
                totalStakedSupply2 == 0 ? "0%" :
                  totalNftSupply2 != 0 && ((Number(totalStakedSupply2) / Number(totalNftSupply2)) * 100).toFixed(1) + " %"
              } staked)
            </div>
          </div>
          <div className="flex flex-row -xl:flex-col space-x-8 -xl:space-x-0 -xl:space-y-4 justify-between items-center mt-8 w-full">
            <div className={`flex flex-row -sm:flex-col space-x-4 -sm:space-x-0 -sm:space-y-4 -sm:px-12 px-4 justify-center items-center ${!dark ? "bg-white" : "bg-black"} rounded-lg py-4`}>
              <div className="flex flex-col justify-center items-center w-[150px]">
                <div>My Staked</div>
                {value == 0 ? stakedNftList.length : stakedNftList2.length} / {value == 0 ? (nftList.length + stakedNftList.length) : (nftList2.length + stakedNftList2.length)}
              </div>
              <div
                className="flex flex-row justify-center items-center bg-[#00396D] text-white rounded-lg py-3 px-8 cursor-pointer w-[170px] text-[15px]"
                onClick={() => handleStakeAll()}
              >
                STAKE ALL
              </div>
              <div
                className="flex flex-row justify-center items-center bg-[#00396D] text-white rounded-lg py-3 px-8 cursor-pointer w-[170px] text-[15px]"
                onClick={() => handleUnstakeAll()}
              >
                UNSTAKE ALL
              </div>
            </div>
            <div className={`flex flex-row -sm:flex-col space-x-4 -sm:space-x-0 -sm:space-y-4 -sm:px-12 px-4 justify-center items-center ${!dark ? "bg-white" : "bg-black"} rounded-lg py-4`}>
              <div className="flex flex-col justify-center items-center w-[150px]">
                <div className="text-[12px]">Earned</div>
                {value == 0 ? claimBalance : claimBalance2} {data.tokenCode}
              </div>
              <div
                className="flex flex-row justify-center items-center bg-[#00396D] text-white rounded-lg py-3 px-8 cursor-pointer w-[170px] text-[15px]"
                onClick={() => onClaim(wallet, value)}
              >
                CLAIM
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Balance;
