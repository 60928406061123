import { useContext, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ProjectList from './Constant';
import Header from './components/Header';
import { WalletContext } from './Wallet';
import * as nearAPI from "near-api-js";

import Balance from './components/Balance';
import StakeUnstake from './components/StakeUnstake';

import Paras from './components/social/Paras';
import Discord from './components/social/Discord';
import Twitter from './components/social/Twitter';
import Website from './components/social/Website';

const { KeyPair, keyStores, connect, WalletConnection, utils: { format: { formatNearAmount } } } = nearAPI;
const keyStore = new keyStores.BrowserLocalStorageKeyStore();

const Projects = () => {
  const { url } = useParams();
  const navigate = useNavigate();
  const {
    wallet,
    stakingContractId,
    dark,
    setancBalance,
    setValue,
    // set constant variables here
    setStakingContractId,
    setStakingContractId2,
    setNftContractId,
    setNftContractId2,
    setTokenContractId,
    setCreatorId,
    setCollectionId
  } = useContext(WalletContext);
  const id = ProjectList.find(project => project.url == url)?.id;
  const data = ProjectList.find(project => project.id == id);

  if(data == undefined) {
    return null;
  }

  useEffect(() => {
    if (!data && !(wallet && wallet.isSignedIn())) {
      navigate('/');
    }
  }, [wallet])

  useEffect(() => {
    (async () => {
      if (wallet && wallet.isSignedIn()) {
        let result = await wallet.account().viewFunction(
          data.tokenContractId,
          "ft_balance_of",
          {
            account_id: wallet.account().accountId,
          }
        );
        setancBalance(formatNearAmount(result));
        // console.log("==============================", ancBalance);
        result = await wallet.account().getAccessKeys();
        let tokenKeyExist = false;
        for (let i = 0; i < result.length; i++) {
          if (result[i].access_key.permission != 'FullAccess' && result[i].access_key.permission.FunctionCall.receiver_id == data.tokenContractId) {
            tokenKeyExist = true;
            break;
          }
        }
        if (tokenKeyExist == false) {
          console.log("Adding AccessKey to Token");
          const keyPair = KeyPair.fromRandom("ed25519");
          const publicKey = keyPair.publicKey.toString();
          await keyStore.setKey("mainnet", publicKey, keyPair);
          await wallet.account().addKey(publicKey, data.tokenContractId, [], '250000000000000000000000');
        }
      } else {
        setancBalance(0);
        // console.log("==============================", ancBalance);
      }
    })()
  }, [wallet, data])

  useEffect(() => {
    if (wallet && wallet.isSignedIn() && data) {
      setValue(0);
      setTokenContractId(data.tokenContractId);
      for (let i = 0; i < data.contracts.length; i++) {
        const ctr = data.contracts[i];
        if (ctr.fromParas) {
          setStakingContractId2(ctr.stakingContractId);
          setNftContractId2(ctr.nftContractId);
          setCreatorId(ctr.creatorId);
          setCollectionId(ctr.collectionId);
        } else {
          setStakingContractId(ctr.stakingContractId);
          setNftContractId(ctr.nftContractId);
        }
      }
    }
  }, [wallet, data])

  const signIn = () => {
    wallet.requestSignIn(stakingContractId);
  };

  const signOut = () => {
    wallet.signOut();
    window.location = "/"
  };

  const onWallet = () => {
    if (wallet.isSignedIn()) {
      signOut();
    } else {
      signIn();
    }
  }


  return (
    <div className={`flex flex-col justify-start items-center min-h-[100vh] ${!dark ? 'bg-white text-black' : 'bg-black text-white'}`}>
      <Header wallet={wallet} onWallet={onWallet} data={data} from="1" />
      <div className='flex flex-col justify-center items-center max-w-[1400px] min-w-[350px] mt-[114px] -lg:mt-[94px]'>
        <div className={`relative h-[250px] flex flex-row justify-center items-center w-full text-white rounded-lg`}
          style={{
            backgroundImage: `url('${data.images.bannerUrl}')`,
            backgroundSize: "cover",
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        >
          <div className="w-32 h-32 overflow-hidden bg-dark-primary-2 z-0 shadow-inner rounded-full mt-64">
            <img 
              src={data.images.logoUrl}
              className="w-full object-cover rounded-full border-4 border-black" 
            />
          </div>
        </div>
        <div className='relative w-full flex flex-row justify-between items-center'>
          <div className="flex flex-row items-center justify-center space-x-8 w-full mt-20 mb-4">
            {
              data.links.paras &&
              <div
                className="relative border-[#00396D] border-[4px] bg-transparent w-[60px] h-[60px] flex flex-row justify-center items-center rounded-[50px] text-[20px] cursor-pointer"
                onClick={() => { window.open(data.links.paras) }}
              > <Paras fill={dark ? "#ffffff" : "000000"} className="absolute top-[17px] left-[19px]" /> </div>
            }
            {
              data.links.discord &&
              <div
                className="relative border-[#00396D] border-[4px] bg-transparent w-[60px] h-[60px] flex flex-row justify-center items-center rounded-[50px] text-[20px] cursor-pointer"
                onClick={() => { window.open(data.links.discord) }}
              > <Discord fill={dark ? "#ffffff" : "000000"} className="absolute top-[17px] left-[15px]" /> </div>
            }
            {
              data.links.twitter &&
              <div
                className="relative border-[#00396D] border-[4px] bg-transparent w-[60px] h-[60px] flex flex-row justify-center items-center rounded-[50px] text-[20px] cursor-pointer"
                onClick={() => { window.open(data.links.twitter) }}
              > <Twitter fill={dark ? "#ffffff" : "000000"} className="absolute top-[18px] left-[15px]" /> </div>
            }
            {
              data.links.website &&
              <div
                className="relative border-[#00396D] border-[4px] bg-transparent w-[60px] h-[60px] flex flex-row justify-center items-center rounded-[50px] text-[20px] cursor-pointer"
                onClick={() => { window.open(data.links.website) }}
              > <Website fill={dark ? "#ffffff" : "000000"} className="absolute top-[14px] left-[14px]" /> </div>
            }
          </div>
        </div>
        {
          wallet && wallet.isSignedIn() ?
            <div className="w-full">
              <Balance data={data} />
              <StakeUnstake data={data} />
            </div> : <></>
        }
      </div>
    </div>
  )
}

export default Projects;