const Discord = (props) => {
  return (
    <svg
      fill={props.fill}
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      viewBox="0 0 30 30"
      width="30px"
      height="30px">
        <path d="M17.777 1.326A17.323 17.323 0 0 0 13.503.001a.065.065 0 0 0-.069.033c-.184.328-.389.756-.532 1.092a15.994 15.994 0 0 0-4.801 0 11.057 11.057 0 0 0-.54-1.092A.067.067 0 0 0 7.49 0c-1.5.258-2.934.71-4.274 1.325a.061.061 0 0 0-.028.024C.467 5.416-.279 9.382.087 13.298c.001.02.012.038.027.05a17.417 17.417 0 0 0 5.244 2.65.068.068 0 0 0 .074-.025 12.44 12.44 0 0 0 1.072-1.744.066.066 0 0 0-.036-.093 11.47 11.47 0 0 1-1.638-.78.067.067 0 0 1-.007-.112c.11-.082.22-.168.325-.255a.065.065 0 0 1 .068-.009c3.437 1.569 7.158 1.569 10.554 0a.065.065 0 0 1 .069.008c.105.087.215.174.326.256a.067.067 0 0 1-.006.112c-.523.305-1.067.564-1.639.78a.067.067 0 0 0-.035.093c.315.61.675 1.192 1.072 1.743a.067.067 0 0 0 .073.025 17.36 17.36 0 0 0 5.252-2.65.067.067 0 0 0 .028-.048c.437-4.528-.734-8.461-3.105-11.948a.053.053 0 0 0-.028-.025zm-10.76 9.588c-1.034 0-1.887-.95-1.887-2.116 0-1.166.836-2.116 1.888-2.116 1.059 0 1.903.958 1.887 2.116 0 1.166-.836 2.116-1.887 2.116zm6.978 0c-1.034 0-1.887-.95-1.887-2.116 0-1.166.836-2.116 1.887-2.116 1.06 0 1.904.958 1.888 2.116 0 1.166-.828 2.116-1.888 2.116z" fillRule="nonzero"></path>
    </svg>
  )
}

export default Discord;