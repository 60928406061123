import React, { useContext, useEffect, useState } from "react";
import { Button, Row } from "react-bootstrap";
import { WalletContext } from "../Wallet";
import Header from "./Header";
import * as buffer from "buffer"
  ; window.Buffer = buffer.Buffer

function NavBar() {
  const { wallet, stakingContractId } = useContext(WalletContext)

  const signIn = () => {
    wallet.requestSignIn(stakingContractId);
  };

  const signOut = () => {
    wallet.signOut();
    window.location = "/"
  };

  const onWallet = () => {
    if (wallet.isSignedIn()) {
      signOut();
    } else {
      signIn();
    }
  }

  return (
    <div>
      {(wallet && wallet.isSignedIn()) ?
        <Header wallet={wallet} onWallet={onWallet} from="0" /> :
        <Row>
          <div className="blank-area">
            <Button className="center-button text-white" onClick={onWallet}>Connect Wallet</Button>
          </div>
        </Row>}
    </div>
  );
}

export default NavBar;
