import { useEffect, useContext, useState } from 'react';
import { WalletContext, MAX_GAS, DEPOSIT } from '../Wallet';

const NFTList = ({
  dark,
  data,
  nftList,
  handleClick,
  title,
  description,
}) => {
  return (
    <>
      {
        nftList.length > 0 ?
          <div className="justify-center items-center grid grid-cols-3 gap-8 -lg:grid-cols-2 -2xl:gap-x-16 -md:gap-x-4 -sm:grid-cols-1">
            {
              nftList.map((item, i) => {
                return (
                  <div key={i} className="flex flex-row w-[400px] -2xl:w-[300px] rounded-lg">
                    {
                      item.collection == 0 ?
                      <img src={item.item.metadata.media.startsWith("http") ? item.item.metadata.media : (item.item.baseUri !== undefined ? `${item.item.baseUri}/${item.item.metadata.media}` : item.item.metadata.media)} className="w-[200px] h-[200px] -2xl:w-[150px] -2xl:h-[150px] rounded-lg" /> :
                      <img src={item.item.metadata.media.startsWith("http") ? item.item.metadata.media : (item.item.baseUri !== undefined ? `${item.item.baseUri}/${item.item.metadata.media}` : `https://ipfs.fleek.co/ipfs/` + item.item.metadata.media)} className="w-[200px] h-[200px] -2xl:w-[150px] -2xl:h-[150px] rounded-lg" />
                    }
                    <div className={`w-[200px] h-[200px] -2xl:w-[150px] -2xl:h-[150px] flex flex-col justify-evenly items-start rounded-lg ${!dark ? 'bg-white' : 'bg-black'} pl-4`}>
                      <div className="flex flex-col justify-center items-start">
                        <div className="-sm:text-[14px] sm:text-[16px]">{item.item.metadata.title}</div>
                        <div className="text-[18px] -sm:text-[16px]">Rate: {data.contracts[item.collection]?.rate} / day</div>
                      </div>
                      <div
                        className="flex flex-row justify-center items-center bg-[#00396D] text-white rounded-lg py-3 cursor-pointer px-6 text-[14px]"
                        onClick={() => { handleClick(item.item.token_id, item.collection) }}
                      >
                        {title}
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div> :
          <div className="flex flex-row justify-center items-center py-12 w-[1400px] -2xl:w-[1028px] -lg:w-[664px] -md:w-[616px] -sm:w-[300px]">
            {description}
          </div>
      }
    </>
  )
}

const StakeUnstake = ({ data }) => {

  const {
    wallet,
    value, // type of collection
    dark,
    nftList,
    nftList2,
    stakedNftList,
    stakedNftList2,
    stakingContractId,
    stakingContractId2,
    nftContractId,
    nftContractId2,
    nftListForStake,
    nftListForUnstake,
    fetchNFTs,
    fetchNFTs2,
    fetchStakedNFTs,
    fetchStakedNFTs2,
    setNftListForStake,
    setNftListForUnstake,
  } = useContext(WalletContext)

  const [selType, setSelType] = useState(0); // type of stake/unstake

  const types = [
    { id: 0, title: "UNSTAKED" },
    { id: 1, title: "STAKED" },
  ];

  useEffect(() => {
    fetchNFTs(wallet);
    fetchNFTs2(wallet);
    fetchStakedNFTs(wallet);
    fetchStakedNFTs2(wallet);
  }, [wallet,stakingContractId, stakingContractId2, nftContractId, nftContractId2, value, selType])

  useEffect(() => {
    setNftListForStake([...generateArr(nftList, 0), ...generateArr(nftList2, 1)]);
    setNftListForUnstake([...generateArr(stakedNftList, 0), ...generateArr(stakedNftList2, 1)]);
  }, [nftList, nftList2, stakedNftList, stakedNftList2])

  const generateArr = (arr, value) => {
    let newArr = [];
    for(let i = 0; i< arr.length; i++) {
      newArr.push({
        item: arr[i],
        collection: value
      })
    }
    return newArr;
  }


  const handleClick = async (token_id, type, tab) => {
    if (type == 0) { // collection type
      if (tab == 0) { // stake/unstake type
        if (data.id == 0 || data.id == 1) { // ape near club | vexed ape near
          await wallet.account().functionCall({
            contractId: nftContractId,
            methodName: "nft_approve",
            args: {
              token_id: token_id,
              account_id: stakingContractId,
              msg: JSON.stringify({ staking_status: "Staking to Platform" })
            },
            gas: MAX_GAS,
            attachedDeposit: DEPOSIT,
          });
        } else {
          await wallet.account().functionCall({
            contractId: nftContractId,
            methodName: "nft_transfer_call",
            args: {
              receiver_id: stakingContractId,
              token_id: token_id,
              approval_id: 0,
              msg: JSON.stringify({ staking_status: "Staking to Platform" })
            },
            gas: MAX_GAS,
            attachedDeposit: "1",
          });
        }
      } else {
        await wallet.account().functionCall({
          contractId: stakingContractId,
          methodName: "unstake",
          args: {
            token_id: token_id,
          },
          gas: MAX_GAS,
          attachedDeposit: "1",
        });
      }
    } else {
      if (tab == 0) {
        await wallet.account().functionCall({
          contractId: nftContractId2,
          methodName: "nft_transfer_call",
          args: {
            receiver_id: stakingContractId2,
            token_id: token_id,
            approval_id: 0,
            msg: JSON.stringify({ staking_status: "Staking to Platform" })
          },
          gas: MAX_GAS,
          attachedDeposit: "1",
        });
      } else {
        await wallet.account().functionCall({
          contractId: stakingContractId2,
          methodName: "unstake",
          args: {
            token_id: token_id,
          },
          gas: MAX_GAS,
          attachedDeposit: "1",
        });
      }
    }
    fetchNFTs(wallet);
    fetchNFTs2(wallet);
    fetchStakedNFTs(wallet);
    fetchStakedNFTs2(wallet);
  }


  return (
    <div className="flex flex-col justify-center items-center w-full my-[50px]">
      <div className={`relative flex flex-col justify-center items-center ${!dark ? 'bg-[#F5F5F5]' : 'bg-[#121212]'} rounded-b-lg rounded-tr-lg p-12 -xl:p-8 -md:px-4 -md:py-8 w-full mt-[60px]`}>
        <div className="absolute left-0 top-[-58px] flex flex-row justify-start h-[80px]">
          {
            types.map((type, index) => {
              return (
                <div
                  key={index}
                  className={`w-[180px] h-[60px] -sm:w-[149px] -sm:text-[14px]
                    ${!dark ? "bg-[#F5F5F5]" : "bg-[#121212]"} 
                    flex flex-row justify-center items-center
                    border-t-4 border-r-4 
                    ${selType == type.id ?
                      "border-t-[#00396D] border-r-[#00396D]" :
                      !dark ? "border-t-[#cccccc] border-r-[#cccccc]" : "border-t-[#272727] border-r-[#272727] bg-[#171717]"
                    } 
                    rounded-t-lg cursor-pointer
                    `
                  }
                  onClick={() => setSelType(type.id)}
                >
                  {type.title}
                </div>
              )
            })
          }
        </div>
        <div className="flex flex-row justify-center items-center">
          <NFTList
            dark={dark}
            data={data}
            nftList={
              selType == 0 ? nftListForStake : nftListForUnstake
            }
            handleClick={(tokenId, value) => {
              handleClick(tokenId, value, selType);
            }}
            title={selType == 0 ? "STAKE" : "UNSTAKE"}
            description={
              selType == 0 ?
                "You don't have any NFT to stake" :
                "You don't have any staked NFT"
            }
          />
        </div>
      </div>
    </div>
  )
}

export default StakeUnstake;